import wishlistServices from '@/kytesoft-client/services/wishlistServices';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addWishlistThunk = createAsyncThunk(
  'wishlist/addWishlist',
  async (id, { rejectWithValue }) => {
    try {
      return await wishlistServices.addWishlist(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteWishlistThunk = createAsyncThunk(
  'wishlist/deleteWishlist',
  async (id, { rejectWithValue }) => {
    try {
      return await wishlistServices.deleteWishlist(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
