import { IProduct } from '@interfaces/product';
import { isEmpty, round } from 'lodash';
import { useMemo } from 'react';

export default function useProductPrice(product?: IProduct) {
  const result: {
    discount: number;
    discountRate: number;
    price?: number;
    salePrice: number;
    hasSale: boolean;
    minPrice?: number;
    maxPrice?: number;
  } = useMemo(() => {
    if (!product) {
      return {
        discount: 0,
        discountRate: 0,
        price: 0,
        salePrice: 0,
        hasSale: false,
      };
    }

    const { price, discount, discountRate, minPrice, maxPrice } = product;

    const hasDiscount = !isEmpty(discount) || discount > 0;
    const hasDiscountRate = !isEmpty(discountRate) || discountRate > 0;

    if (!hasDiscount && !hasDiscountRate)
      return {
        discount: 0,
        discountRate: 0,
        salePrice: price,
        hasSale: false,
        ...(minPrice && { minPrice }),
        ...(maxPrice && { maxPrice }),
        ...(price && { price }),
      };

    if (hasDiscount && !hasDiscountRate) {
      if (!price && minPrice && maxPrice) {
        const saleMinPrice = minPrice - discount;
        const resultDiscountRate = round((discount / minPrice) * 100, 2);

        return {
          discount,
          discountRate: resultDiscountRate,
          minPrice,
          maxPrice,
          price: minPrice,
          salePrice: saleMinPrice,
          hasSale: true,
        };
      }

      const salePrice = price - discount;
      const resultDiscountRate = round((discount / price) * 100, 2);

      return {
        discount,
        discountRate: resultDiscountRate,
        price,
        salePrice: salePrice,
        hasSale: true,
      };
    }

    if ((!hasDiscount && hasDiscountRate) || (hasDiscount && hasDiscountRate)) {
      if (!price && minPrice && maxPrice) {
        const discountPrice = (minPrice * discountRate) / 100;
        const saleMinPrice = minPrice - discountPrice;

        return {
          discount: discountPrice,
          discountRate,
          minPrice,
          maxPrice,
          price: minPrice,
          salePrice: saleMinPrice,
          hasSale: true,
        };
      }

      const discountPrice = (price * discountRate) / 100;
      const salePrice = price - discountPrice;

      return {
        discount: discountPrice,
        discountRate: discountRate,
        price,
        salePrice: salePrice,
        hasSale: true,
      };
    }

    return {
      discount: 0,
      discountRate: 0,
      price,
      salePrice: price,
      hasSale: false,
      minPrice,
      maxPrice,
    };
  }, [product]);

  return result;
}
