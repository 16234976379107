import { useAuth } from '@/kytesoft-client/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addWishlistThunk, deleteWishlistThunk } from '@/kytesoft-client/store/wishlist/thunks';
import { thunkWrapper } from '@/kytesoft-client/helpers';
import { get, noop } from 'lodash';

export const useWishlist = ({
  onAddWishlistSuccess = () => {},
  onAddWishlistError = () => {},
  onDeleteWishlistSuccess = () => {},
  onDeleteWishlistError = () => {},
  messages = {},
} = {}) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  const addItemToWishlist = useCallback(
    async (id) => {
      if (!isAuthenticated) return onAddWishlistError(get(messages, 'addToWishlistUnauthorized'));

      await thunkWrapper({
        promise: dispatch(addWishlistThunk(id)),
        thunkAction: addWishlistThunk,
        onError: onAddWishlistError || noop,
        onSuccess: onAddWishlistSuccess || noop,
      });
    },
    [isAuthenticated, dispatch, onAddWishlistError, onAddWishlistSuccess, messages],
  );

  const removeItemFromWishlist = useCallback(
    async (id) => {
      await thunkWrapper({
        promise: dispatch(deleteWishlistThunk(id)),
        thunkAction: deleteWishlistThunk,
        onError: onDeleteWishlistError || noop,
        onSuccess: onDeleteWishlistSuccess || noop,
      });
    },
    [dispatch, onDeleteWishlistError, onDeleteWishlistSuccess],
  );

  return {
    addItemToWishlist,
    removeItemFromWishlist,
  };
};
