import MuiRating from '@mui/material/Rating';
import ratingStyles from '@scss/components/rating.scss';
import classNames from 'classnames';
import { FC } from 'react';

interface IRatingProps {
  className?: string;
  value: number;
  readOnly?: boolean;
  color?: 'primary' | 'danger' | 'warning';
  size?: 'sm' | 'md' | 'lg';
}

export const Rating: FC<IRatingProps> = ({ color, size, value, readOnly }) => {
  return (
    <>
      <style jsx>{ratingStyles}</style>
      <MuiRating
        value={value}
        readOnly={readOnly}
        className={classNames('ks-rating', `-${size}`, `-${color}`)}
      />
    </>
  );
};

Rating.defaultProps = {
  color: 'primary',
  size: 'sm',
  readOnly: false,
};
